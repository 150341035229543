'use strict';

angular
  .module('estimably')
  .service('analyticsService', function() {
    return {
      record: function(name, data) {
        window._kmq.push(['record', name, data]);
      }
    };
  });
