'use strict';

angular.module('estimably')
	.controller('ResultCtrl', ["$scope", "gameService", function ($scope, gameService) {
	$scope.game = gameService;
	$scope.story = gameService.getGameData();
	$scope.results = [];

	var transform = function(clientEstimate){
		return {playerName: clientEstimate.playerName, clientId:clientEstimate.clientId};
	};

	$scope.sortResults = function() {
		var results = [];
		var getScore = function(result) {return result.score;};



		var estimates = gameService.getEstimates();
		function getIntrinsicOrder(value){
		 return gameService.valueScale.indexOf(value);
		}
		for(var i = 0; i < estimates.length; i++){
			var resultIndex = results.map(getScore).indexOf(estimates[i].value);
			if(resultIndex !== -1){
				results[resultIndex].estimates.push(transform(estimates[i]));
			}
			else{
				results.push({score: estimates[i].value, estimates: [transform(estimates[i])]});
			}
		}
		results = results.sort(function(a, b) {
			var aTally = a.estimates.length, bTally = b.estimates.length;
			if (aTally === bTally){
				return getIntrinsicOrder(a.score) - getIntrinsicOrder(b.score);
			}
			return bTally - aTally;
		});
		return results;
	};

	$scope.results = $scope.sortResults();

}]);
