'use strict';

angular.module('estimably').factory('browserService', function() {
		return {
			isMobile: function() {
				var userAgent = navigator.userAgent || navigator.vendor || window.opera;
				return /(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i.test(userAgent);
			}
		};
	});
