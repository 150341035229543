'use strict';

angular.module('estimably').directive('autoFocus', ["browserService", function(browserService) {
	return {
		link: {
			post: function postLink(scope, element) {
				if(!browserService.isMobile()) {
					element[0].focus();
				}
			}
		}
	};
}]);
