/* global NREUM */
'use strict';

angular
	.module('estimably', [
		'ngRoute',
		'localization'
	])
	.config(["$provide", "$routeProvider", "$logProvider", function ($provide, $routeProvider, $logProvider) {
		$provide.decorator('$exceptionHandler', ["$delegate", function($delegate){
		        return function(exception, cause){
							$delegate(exception,cause);
							NREUM.noticeError(exception);
		        };
		    }]);

		$logProvider.debugEnabled(window.location.toString().toLowerCase().indexOf('debug=true') > -1);

		$routeProvider
			.when('/', {
				templateUrl: 'views/main.html',
				controller: 'MainCtrl'
			})
			.when('/waiting', {
				templateUrl: 'views/waiting.html',
				controller: 'WaitingCtrl'
			})
			.when('/game', {
				templateUrl: 'views/gamestart.html',
				controller: 'GameCtrl'
			})
			.when('/results', {
				templateUrl: 'views/results.html',
				controller: 'ResultCtrl'
			})
			.otherwise({
				redirectTo: '/'
			});
	}])
	.run(["$rootScope", "$window", "$location", "$log", "gameService", function ($rootScope, $window, $location, $log, gameService) {
		$log.debug('running application');

		$rootScope.$on('$routeChangeStart', function (event, next) {
			if ($location.search().game) {
				gameService.sessionId = $location.search().game;
				$location.search('game', null);
			}

			if (gameService.sessionId === '') {
				if (next.templateUrl === 'views/main.html') {
					// do nothing
				} else {
					$location.path('/');
				}
			}
		});

		$rootScope.$on('$routeChangeSuccess', function () {
			$window.scrollTo(0, 0);
		});
	}]);

angular.module('estimablyMock', []);
