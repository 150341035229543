'use strict';

angular.module('estimably')
	.controller('ToastCtrl', ["$scope", "$rootScope", "$log", function ($scope, $rootScope, $log) {
		var clearToast, cancel, apply, setStatus;

		var unbindSetStatus = $rootScope.$on('setStatus',
			function(event, messageType, message, timeout){
				setStatus(messageType, message, timeout);
			});

		var unbindClear = $rootScope.$on('clearStatus',
			function(){
				clearToast();
			});

		var unbindCancel = $rootScope.$on('cancelToast',
			function (){
				cancel();
			});

		$scope.$on('$destroy', unbindSetStatus);
		$scope.$on('$destroy', unbindClear);
		$scope.$on('$destroy', unbindCancel);

		var toast = { status: {code: false}, message: '', shown: false, active: false};
		var status = toast.status;
		$scope.toast = toast;

		clearToast = function () {
			angular.forEach(status, function (value, key) {
				status[key] = false;
			});
		};

		cancel = function () {
			toast.active = false;
			toast.message = '';
			toast.shown = false;
			apply();
		};

		apply = function(){
			$log.debug('Phase is ' + $rootScope.$$phase);
			if(!($rootScope.$$phase === '$apply' || $rootScope.$$phase === '$digest')){
				$rootScope.$apply();
			} else {
				setTimeout(apply,300);
			}
		};

		setStatus = function (code, message, timeout) {
			$log.debug('setStatus called [' + code + '],[' + message + ']');
			clearToast();
			toast.active = true;
			toast.message = message;
			status[code] = true;
			toast.shown = true;
			apply();
			if (timeout) {
				setTimeout(function () {
					toast.shown = false;
					apply();
					setTimeout(function () {
						toast.active = false;
						apply();
					}, 300);
				}, timeout);
			}
		};
	}]);
