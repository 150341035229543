'use strict';

angular.module('estimably').controller('GameGridCtrl', ["$scope", "gameService", function ($scope, gameService) {

	$scope.selectedValue = '';

	$scope.$watch(function(){
		return gameService.selectedValue;
	}, function(newValue){
		$scope.selectedValue = newValue;
	});

	$scope.$watch('selectedValue', function(newValue){
		gameService.selectedValue = newValue;
	});

	$scope.values = gameService.valueScale;
	$scope.cards = [
		{color: 'red',value: $scope.values[0]},{color: 'peach',value: $scope.values[1]},
		{color: 'copper',value: $scope.values[2]},{color: 'tan',value: $scope.values[3]},
		{color: 'yellow',value: $scope.values[4]},{color: 'olive',value: $scope.values[5]},
		{color: 'lime',value: $scope.values[6]},{color: 'green',value: $scope.values[7]},
		{color: 'aquamarine',value: $scope.values[8]},{color: 'teal',value: $scope.values[9]},
		{color: 'purple',value: $scope.values[10]},{color: 'magenta',value: $scope.values[11]}
	];

	$scope.selected = function(card) {
		if ($scope.selectedValue !== card.value) {
			$scope.$parent.selected(card.value);
			$scope.selectedValue = card.value;
		} else {
			$scope.$parent.unselected();
			$scope.selectedValue = '';
		}
	};

}]);
