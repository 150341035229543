'use strict';

/*
 * An AngularJS Localization Service
 *
 * Written by Jim Lavin with updates from Tony Ashworth
 * http://codingsmackdown.tv
 *
 */

angular.module('localization', []).
  factory('localize', ['$http', '$rootScope', '$window', '$filter', function ($http, $rootScope, $window, $filter) {
    var localize = {
      // use the $window service to get the language of the user's browser
      language: $window.navigator.userLanguage || $window.navigator.language,
      // array to hold the localized resource string entries
      dictionary: [],
      // array to hold the default values
      defaults: [],
      // flag to indicate if the service hs loaded the resource file
      resourceFileLoaded: false,

      successCallback: function (data) {
        // store the returned array in the dictionary
        localize.dictionary = data;
        // set the flag that the resource are loaded
        localize.resourceFileLoaded = true;
        // broadcast that the file has been loaded
        $rootScope.$broadcast('localizeResourcesUpdates');
      },

      successDefaultCallback: function (data) {
        // store the default dictionary
        localize.defaults = data;
        // set the flag that the resource are loaded
        localize.resourceFileLoaded = true;
        // broadcast that the file has been loaded
        $rootScope.$broadcast('defaultLocalizeResourcesUpdates');
      },

      initLocalizedResources: function () {
        // build the url to retrieve the localized resource file
        var url = '/i18n/resources-locale_' + localize.language + '.json';
        // request the resource file
        $http({ method: 'GET', url: url, cache: false }).success(localize.successCallback);
        // request the default resource file
        url = '/i18n/resources-locale_default.json';
        // request the default resource file
        $http({ method: 'GET', url: url, cache: false }).success(localize.successDefaultCallback);
      },

      getLocalizedString: function (value) {
        // default the result to an empty string
        var result = '';
        var entry = null;
        // check to see if the resource file has been loaded
        if (!localize.resourceFileLoaded) {
          // call the init method
          localize.initLocalizedResources();
          // set the flag to keep from looping in init
          localize.resourceFileLoaded = true;
          // return the empty string
          return result;
        }
        // make sure the dictionary has valid data
        if ((localize.dictionary !== []) && (localize.dictionary.length > 0)) {
          // use the filter service to only return those entries which match the value
          // and only take the first result
          entry = $filter('filter')(localize.dictionary, {key: value})[0];
          // check to make sure we have a valid entry
          if ((entry !== null) && (entry !== undefined)) {
            // set the result
            result = entry.value;
          }
        }

        if (result === '') {
          // make sure the dictionary has valid data
          if ((localize.defaults !== []) && (localize.defaults.length > 0)) {
            // use the filter service to only return those entries which match the value
            // and only take the first result
            entry = $filter('filter')(localize.defaults, {key: value})[0];
            // check to make sure we have a valid entry
            if ((entry !== null) && (entry !== undefined)) {
              // set the result
              result = entry.value;
            }
          }
        }
        // return the value to the call
        return result;
      }
    };
    // return the local instance when called
    return localize;
  } ]).
  filter('i18n', ['localize', function (localize) {
    return function (input) {
      return localize.getLocalizedString(input);
    };
  }]);
