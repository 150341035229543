'use strict';

/* global io */

angular.module('estimably').factory('socketService', ["$rootScope", "$location", "$log", "gameService", "toastService", function ($rootScope, $location, $log, gameService, toastService) {
	var socket = null;

	var setup = function() {
		socket.on('reconnect', function () {
			toastService.showSuccess('Connected to Estimably.', 2000);
		});

		// - "disconnect" is emitted when the socket disconnected
		socket.on('disconnect', function (reason) {
			if(reason !== 'io client disconnect') {
				toastService.showDanger('Lost connection to Estimably.');
			}
		});

		// - "connect_failed" is emitted when socket.io fails to establish a connection to the server and has no more transports to fallback to.
		socket.on('connect_failed', function () {
			toastService.showDanger('Unable to connect to game server.');
		});

		// - "error" is emitted when an error occurs and it cannot be handled by the other event types.
		socket.on('error', function () {
			toastService.showDanger('An error has occurred.', 4000);
		});

		socket.on('reconnect_attempt', function () {
			$log.debug('reconnect_attempt');
			toastService.showInfo('Attempting to connect.');
		});

		socket.on('reconnect_failed', function () {
			toastService.showDanger('Unable to connect to game server.');
		});
	};

	return {
		connect: function (hostUrl) {
			socket = io(hostUrl, {forceNew:true});
			setup();
		},
		once: function(eventName, callback){
			socket.once(eventName, function () {
				var args = arguments;
				$log.debug('socket.once: ' + eventName + ', received with args: ' + JSON.stringify(args));
				$rootScope.$apply(function () {
					callback.apply(socket, args);
				});
			});
		},
		on: function (eventName, callback) {
			socket.on(eventName, function () {
				var args = arguments;
				$log.debug('socket.on: ' + eventName + ', received with args: ' + JSON.stringify(args));
				$rootScope.$apply(function () {
					callback.apply(socket, args);
				});
			});
		},
		emit: function (eventName, data, callback) {
			$log.debug('socket.emit: ' + eventName + ', with data: ' + JSON.stringify(data));
			socket.emit(eventName, data, function () {
				var args = arguments;
				$log.debug('socket.emit: ' + eventName + ', finished with args: ' + JSON.stringify(args));
				$rootScope.$apply(function () {
					if (callback) {
						callback.apply(socket, args);
					}
				});
			});
		},

		exit: function () {
			socket.disconnect();
			socket.close();
		}
	};
}]);
