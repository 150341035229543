'use strict';

angular.module('estimably').factory('toastService', ["$rootScope", function ($rootScope) {
	var root = $rootScope;
	return {
		showDanger: function (message, timeout) {
			root.$emit('setStatus','danger', message, timeout);
		},
		showWarning: function (message, timeout) {
			root.$emit('setStatus','warning', message, timeout);
		},
		showSuccess: function (message, timeout) {
			root.$emit('setStatus','success', message, timeout);
		},
		showInfo: function (message, timeout) {
			root.$emit('setStatus','info', message, timeout);
		},
		clear: function () {
			root.$emit('clearStatus');
		},
		cancel: function () {
			root.$emit('cancelToast');
		}
	};
}]);