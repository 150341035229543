'use strict';

angular.module('estimably')
.controller('GameCtrl', ["$scope", "$location", "$log", "gameService", "socketService", function ($scope, $location, $log, gameService, socketService) {

	$scope.values = gameService.valueScale;
	$scope.game = gameService;
	$scope.story = gameService.getGameData();
    $scope.progress = gameService.getProgress();

	$scope.selected = function(value){
		socketService.emit('estimate', {value: value});
	};

	$scope.unselected = function(){
		socketService.emit('clear-estimate');
	};
}]);
