'use strict';

angular.module('estimably').factory('gameService', function() {

	var gameData = {};
	var gameProgress = {};
	var estimates = [];
	return{
		sessionId:'',
		playerName:'',
		valueScale:'',
		clientId:'',
		sync:function(data){
			this.sessionId = data.sessionId || data.session;
			this.playerName = data.playerName;
			this.valueScale = data.options ? data.options.valueScale : [];
			this.clientId = data.clientId;
		},
		setGameData: function(data) {
			if(data.roundId !== gameData.roundId) {
				angular.forEach(gameData, function(value, key){
					delete gameData[key];
				});
				angular.forEach(data, function(value, key){
					gameData[key] = value;
				});
				this.selectedValue = '';
			}
		},
		getGameData: function() {
			return gameData;
		},
		setProgress: function(data){
			angular.forEach(data, function(value, key){
				gameProgress[key] = value;
			});
		},
		getProgress: function(){
			return gameProgress;
		},
		setEstimates: function(data){
			estimates.length = 0;
			data.forEach(function(value){
				estimates.push(value);
			});
		},
		getEstimates: function(){
			return estimates;
		},
		setResults: function(data){
			this.setProgress(data.progress);
			this.setEstimates(data.estimates);
		},
		clear:function(){
			this.clearGameData();
			this.selectedValue = '';
			this.sessionId = '';
			this.playerName = '';
			this.valueScale = '';
			this.clientId = '';
		},
		clearGameData: function () {
			gameData = {};
		}
	};
});
