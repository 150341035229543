'use strict';

angular.module('estimably')
	.controller('MainCtrl', ["$scope", "$http", "$location", "$log", "$window", "socketService", "gameService", "toastService", "analyticsService", "platformService", "localize", function ($scope, $http, $location, $log, $window, socketService, gameService, toastService, analyticsService, platformService, localize) {

		$scope.sessionRequest = {};

		if (gameService.sessionId) {
			$scope.sessionRequest.session = gameService.sessionId;
		}

		$scope.sessionJoinInProgress = false;

		$scope.joinSession = function () {
			toastService.cancel();
			$scope.sessionForm.submitted = true;
			$scope.responseMessage = null;
			if ($scope.sessionForm.$valid && !$scope.sessionJoinInProgress) {
				toastService.showInfo('Attempting to connect.');
				$scope.sessionJoinInProgress = true;
				$http.get('/gameManager/gameServerUrl/' + $scope.sessionRequest.session).then(function(response)
				{
					var hostUrl = response.data;

					socketService.connect(hostUrl);
					$scope.start($scope.sessionRequest, function (responseMessage) {
						toastService.showDanger(responseMessage);
					});
				}, function(response) {
						$scope.sessionJoinInProgress = false;
						var responseMessage =  (response.status === 404) ? '_Main_Toaster_InvalidGameId_'
							: '_Main_Toaster_UnableToConnect_';
						responseMessage	= localize.getLocalizedString(responseMessage);
						toastService.showDanger(responseMessage);
				});
			}
		};

		$scope.cancelJoin = function() {
			$window.location.reload();
		};

		$scope.start = function(sessionRequest, callback) {

			socketService.once('connect', function() {
				toastService.cancel();
				$scope.state = 'waiting';
				socketService.emit('join-session', sessionRequest, function (response) {
					if (response.success) {
						gameService.sync(angular.extend({}, sessionRequest, response));

						analyticsService.record('Joined Session', angular.extend({
							sessionId: gameService.sessionId.toUpperCase(),
							playerName: gameService.playerName
						}, platformService.getInfo()));

						$location.path($scope.state);
						$scope.state = '';
					}
					else {
						$scope.state = '';
						callback(response.message);
						socketService.exit();
						gameService.clear();
					}
					$scope.sessionJoinInProgress = false;
				});
			});

			socketService.on('reconnect', function () {
				if (gameService.sessionId === '' || gameService.sessionId === null) {
					$location.path('/main');
					gameService.clear();
				} else {
					var sessionRequest = {
						session: gameService.sessionId,
						playerName: gameService.playerName,
						clientId: gameService.clientId
					};
					$scope.state = '';
					socketService.emit('join-session', sessionRequest, function (response) {
						if (response.success) {
							$log.debug('RE-connected to game');
							toastService.showSuccess('Reconnected to Estimably', 4000);

							if ($scope.state !== '') {
								$location.path($scope.state);
							}
						}
						else {
							$scope.state = '';
							$log.debug(response.message);
							$location.path('/main');
							socketService.exit();
							gameService.clear();
							toastService.cancel();
						}
					});
				}
			});

			socketService.on('estimation-started', function (data) {
				if (gameService.getGameData().roundId === data.roundId) {
					if (gameService.selectedValue !== '') {
						socketService.emit('estimate', {value: gameService.selectedValue});
					} else {
						socketService.emit('clear-estimate');
					}
				}
				$scope.state = 'game';
				gameService.setGameData(data);
				$location.path($scope.state);
			});

			socketService.on('estimation-progress', function (data) {
				gameService.setProgress(data);
			});

			socketService.on('estimation-ended', function (data) {
				$scope.state = 'results';
				gameService.setResults(data);
				$location.path($scope.state);
			});

			socketService.on('dealer-disconnected', function () {
				toastService.showWarning('The facilitator disconnected.', 4000);
			});

			socketService.on('session-ended', function () {
				$scope.state = '';
				socketService.exit();
				gameService.clear();
				$location.path('/main');
				toastService.showInfo('The facilitator has ended the game.', 4000);
			});

			socketService.on('facilitator-timeout', function () {
				console.log('facilitator-timeout');
				gameService.clear();
				socketService.exit();
				$location.path('/main');
				toastService.showWarning('The facilitator has timed out.', 4000);
			});
		};
	}]);
