'use strict';

angular.module('estimably')
	.controller('DisconnectCtrl', ["$scope", "$location", "$log", "socketService", "gameService", function ($scope, $location, $log, socketService, gameService) {
		$scope.disconnect = function() {
			socketService.emit('leave-session', $scope.sessionRequest);
			$log.debug('leaving game');
			gameService.playerName = '';
			gameService.sessionId = '';
			gameService.selectedValue = '';
			$location.path('/');
			socketService.exit();
		};
	}]);
