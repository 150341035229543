'use strict';

angular.module('estimably')
  .factory('versionService', ["$http", "$rootScope", "$log", function ($http, $rootScope, $log) {
      return {
        getAppVersion: function () {
          $http({ url: '/version', method: 'GET' })
            .success(function(response) {
              $log.debug('Version Returned From Server:' + response);
              $rootScope.appVersion = response;
            });
        }};
  }]);
