'use strict';

angular
  .module('estimably')
  .service('platformService', function() {
    return {
      getInfo: function() {
        var p = window.platform;

        return {
          platform: p.description,
          name: p.name,
          version: p.version,
          os: p.os.toString(),
          ua: p.ua
        };
      }
    };
  });
